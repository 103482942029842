import React from 'react';
import slugify from 'react-slugify';

import { generateYearsBetween } from 'utils/date-time';
import { Props } from './ScholarshipWinners.interface';

import {
  scholarshipWinners__wrapper,
  scholarshipWinners__header,
  scholarshipWinners,
  scholarshipWinners__winner,
} from './ScholarshipWinners.scss';

export default ({ winners }: Props): JSX.Element => {
  const getWinnersByYear = (year: string) => winners.filter((winner) => winner.node.year === year);
  const years = generateYearsBetween(2015, 2023).reverse();

  return (
    <>
      <div className={scholarshipWinners__wrapper}>
        <h2 className={scholarshipWinners__header}>Scholarship Winners</h2>
        <div className={scholarshipWinners}>
          {years.map((year) => (
            <div key={`scholarship-year-${year}`}>
              <h3>{year}</h3>
              {getWinnersByYear(year.toString()).map(({ node }) => (
                <div className={scholarshipWinners__winner} key={`${node.year}-${slugify(node.name)}}`}>
                  <h4>{node.name}</h4>
                  <span>
                    {node.course_name} student at <br />
                    {node.university}
                  </span>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
